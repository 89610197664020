
html{
  background: '#303733';
}
.App {
  text-align: center;
}

.App-logo {
  height: 6vh;
}

.header {
  background-color: #673ab7;
  min-height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16rem;
  /*margin-bottom: 3vh;*/
  /*position: fixed;*/
}
.header-menu{
  display: flex;
}
.header-link {
  color: #4ed3a0;
  font-size: 1.6rem;
  text-decoration: none;
  margin: 0 1rem;
  cursor: pointer;
}
.header-link:hover {
  color: #4ed3a0;
  font-weight: bolder;
}

.segment{
  width: 70vw;
  margin:0 auto;
  font-family: 'X360 by Redge', sans-serif;
  display: flex;
  align-items: center;
}
.segment-description{
  font-size: 17px;
}
.trademark{
    font-size: 1.2rem;
    color: #673ab7;
    margin-top: 2rem;
}
.segment-content{
  max-width: 70vh;
  margin-right: 0vw;
}
.footprint{
  font-size:0.9rem;
}
.l-bold{
    font-weight: bolder;
    font-size: 1.2rem;
}
.segment-image{
  contain-intrinsic-size: auto 5000px;
  /*width: 39vw;*/
  height: 39vh;
  margin-left: 30px;
  margin-top: 2rem;
}
.footer{
  margin-top: 3rem;
    background-color: #673ab7;
    color: white;
    padding: 1rem;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;

}
.segment-image.smaller{
    /*width: 30vw;*/
    height: 30vh;

}
@media screen and (max-width: 1024px) {
  .header {
    padding: 0 2rem;
    min-height: 8vh;
  }
  .App-logo{
    height: 6vh;

  }
  .header-link{
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .header {
    padding: 0 1rem;
    min-height: 7vh;
  }
  .App-logo{
    height: 5vh;

  }
  .header-link{
    font-size: 1.4rem;
  }
  .header-link:nth-child(3){
    display: none;
  }

  .segment {
    width: 70vw;
    margin: 0 auto;
    font-family: 'X360 by Redge', sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .segment-image,
  .segment-image.smaller{
    width: 90vw;
  }
}
