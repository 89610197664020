.logo-splash{
    -webkit-animation:spin 2.2s linear infinite;
    -moz-animation:spin 2.2s linear infinite;
    animation:spin 2.2s linear infinite;
    width: 20vw;
    /*filter: brightness(0.5);*/
}

@media screen and (max-width: 1024px) {
    .logo-splash{
        width: 50vw;
    }
}
@media screen and (max-width: 768px) {
    .logo-splash{
        width: 70vw;
    }
}
@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
    }
    40% {
        -moz-transform: rotate(-40deg);
    }
    60% {
        -moz-transform: rotate(-40deg);
    }
    90% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(0deg);
    }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(-40deg);
    }
    60% {
        -webkit-transform: rotate(-40deg);
    }
    90% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform:rotate(0deg);
    }
    40% {
        -webkit-transform: rotate(-40deg);
        transform:rotate(-40deg);
    }
    60% {
        -webkit-transform: rotate(-40deg);
        transform:rotate(-40deg);
    }
    90% {
         -webkit-transform: rotate(0deg);
         transform:rotate(0deg);
     }
    100% {
         -webkit-transform: rotate(0deg);
         transform:rotate(0deg);
     }
}