@font-face {
  font-family: 'X360 by Redge';
  font-style: normal;
  font-weight: normal;
  src: local('X360 by Redge'), url('X360.woff') format('woff');
}

body {
  margin: 0;
  font-family: X360 by Redge, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #38373b;*/
  background: #000000;

  color:white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
